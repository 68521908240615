export const  exportedColor  =  {
    primary: {
        light: '#a44fbb',
        main: '#8e24aa',
        dark: '#631976',
    },
    secondary: {
        light: '#ed4b82',
        main: '#e91e63',
        dark: '#a31545',
    }
}