import { CircularProgress } from "@mui/material"



function LoadingData() {



    return (

        <CircularProgress />

    )
}

export default LoadingData